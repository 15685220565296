import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import {Bindings} from "data/constants/bindings";
import {LeaderboardPhase} from "data/enums";
import type {ILeaderboardStore} from "data/stores/leaderboard/leaderboard.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISponsorStore} from "data/stores/sponsor/sponsor.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import * as React from "react";

export interface ILeaderboardFiltersController extends ViewController {
	get i18n(): ILocalizationStore;

	get selectedPhase(): LeaderboardPhase;

	get isSecondPhaseVisible(): boolean;

	onPhaseChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
}

@injectable()
export class LeaderboardFiltersController implements ILeaderboardFiltersController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaderboardStore) private _leaderboardStore: ILeaderboardStore,
		@inject(Bindings.SponsorStore) private _sponsorStore: ISponsorStore
	) {
		makeAutoObservable(this);
	}

	public onPhaseChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
		const value = event.target.value as LeaderboardPhase;

		if (!value) {
			return;
		}

		this._leaderboardStore.updatePhase(value);
	};

	get selectedPhase(): LeaderboardPhase {
		return this._leaderboardStore.filters.type;
	}

	get isSecondPhaseVisible(): boolean {
		return Boolean(this._sponsorStore.settings?.isSecondPhaseEnabled);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}
}
