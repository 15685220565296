import {Bindings} from "data/constants/bindings";
import {ModalType, RoundStatus} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISponsorStore} from "data/stores/sponsor/sponsor.store";
import type {IMatch} from "data/types/entities";
import {ViewController} from "data/types/structure";
import {MatchUtil} from "data/utils/match.util";
import {inject, injectable} from "inversify";
import {first} from "lodash";
import {
	action,
	IReactionDisposer,
	makeAutoObservable,
	observable,
	reaction,
	runInAction,
} from "mobx";

export interface IActionBannerController extends ViewController {
	save: () => void;

	gotoNextRound: () => void;

	get i18n(): ILocalizationStore;

	get isSaveDisabled(): boolean;

	get isLoading(): boolean;

	get isSaved(): boolean;

	get isComplete(): boolean;

	get isLocked(): boolean;

	get isPredictionsMade(): boolean;

	get isAnyPredictionsMade(): boolean;

	get correctPredictions(): number;

	get totalPredictions(): number;

	get savedPredictions(): number;

	get totalMatches(): number;
}

@injectable()
export class ActionBannerController implements IActionBannerController {
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _isLoading: boolean = false;
	@observable private _isSaved: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SponsorStore) private _sponsorStore: ISponsorStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	public get isSaved(): boolean {
		return this._isSaved;
	}

	public get isLoading(): boolean {
		return this._isLoading;
	}

	public get isSaveDisabled(): boolean {
		return !this.roundId || this._isLoading || !this._predictionsStore.predictionsChanged;
	}

	public get isLocked(): boolean {
		return this.matches.some((match) => MatchUtil.IS_MATCH_LOCKED(match));
	}

	public get isComplete(): boolean {
		return this.matches.every((match) => MatchUtil.IS_MATCH_COMPLETE(match));
	}

	public get correctPredictions(): number {
		return this.predictions.filter((prediction) => prediction.win === true).length;
	}

	public get isPredictionsMade(): boolean {
		return this.savedPredictions > 0;
	}

	public get isAnyPredictionsMade(): boolean {
		return this._predictionsStore.uniqPredictions.length > 0;
	}

	public get totalPredictions(): number {
		return this.predictions.length;
	}

	public get savedPredictions(): number {
		return this._predictionsStore.uniqPredictions.length;
	}

	public get totalMatches(): number {
		return this.matches.length > 4 ? 4 : this.matches.length;
	}

	protected get matches(): IMatch[] {
		return this._roundsStore.currentRound?.matches || [];
	}

	protected get roundId(): number | undefined {
		return this._roundsStore.currentRound?.id;
	}

	private get predictions() {
		return this._predictionsStore.roundPredictions;
	}

	private get isBeforeDoublePointsDay() {
		if (!this.nearestDoubleRound) {
			return false;
		}
		return this._roundsStore.currentRound?.id === this.nearestDoubleRound - 1;
	}

	private get sharedDoubleRounds() {
		return this._sponsorStore.sharedDoubleRounds;
	}

	private get nearestDoubleRound(): number | undefined {
		const doublePointsRounds = this.sharedDoubleRounds;
		const currentRound = this._roundsStore.currentRound;
		if (!doublePointsRounds || !currentRound) {
			return;
		}
		const futureRounds = doublePointsRounds.filter((roundId) => roundId >= currentRound.id);

		return first(futureRounds);
	}

	private get doublePointsRound() {
		if (!this.nearestDoubleRound) {
			return false;
		}
		return this._roundsStore.currentRound?.id === this.nearestDoubleRound;
	}

	private get isDoublePointsDay() {
		const isActive = this._roundsStore.currentRound?.status !== RoundStatus.Complete;

		return Boolean(this.doublePointsRound) && isActive;
	}

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		this.updateSavedState();
		const subscription = reaction(
			() => [this._predictionsStore.uniqPredictions],
			() => this.updateSavedState()
		);
		this._subscriptions$.push(subscription);
	}

	onChange(param: void): void {
		return;
	}

	public gotoNextRound = () => {
		const currentRound = this._roundsStore.currentRound;
		const currentRoundIndex = this._roundsStore.list.findIndex(
			(e) => e.id === currentRound?.id
		);

		if (currentRoundIndex === -1) {
			return;
		}

		const nextRound = this._roundsStore.list[currentRoundIndex + 1];

		if (!nextRound) {
			return;
		}

		this._roundsStore.selectRoundById(nextRound.id);
	};

	public save = () => {
		if (!this.roundId) {
			return;
		}

		this._predictionsStore
			.savePredictionsForRound(this.roundId)
			.then(() => {
				this.checkModals();
				this._isSaved = true;
			})
			.catch(this._modalsStore.showErrorFromCatch)
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	};

	@action
	protected updateSavedState() {
		this._isSaved = false;
	}

	private checkModals() {
		if (this.isBeforeDoublePointsDay) {
			this._modalsStore.showModal(ModalType.PRE_DOUBLE_POINTS);
			return;
		}

		if (this.isDoublePointsDay) {
			this._modalsStore.showModal(ModalType.DOUBLE_POINTS);
			return;
		}

		this._modalsStore.showModal(ModalType.PICKS_CONFIRM);
	}
}
