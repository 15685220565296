import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import React from "react";
import {WithNavigate} from "data/types/general";
import type {NavigateFunction} from "react-router-dom";
import type {IUserStore} from "data/stores/user/user.store";
import {IRegisterPayload} from "data/providers/api/auth.api.provider";
import {AxiosApiErrorGeneric} from "data/types/api";
import {getCheckUsernameErrorObject} from "data/utils";
import {debounce} from "lodash";
import Cookies from "js-cookie";

export interface ILandingController extends ViewController<WithNavigate> {
	onUsernameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onSubmit: (event: React.SyntheticEvent<HTMLFormElement>) => void;
	checkUsername: () => void;

	get i18n(): ILocalizationStore;

	get username(): string;

	get terms(): boolean;

	get errorMessage(): string;

	get isSubmitDisabled(): boolean;
}

@injectable()
export class LandingController implements ILandingController {
	@observable private _navigate: NavigateFunction | undefined;
	@observable private _terms: boolean = true;
	@observable private _username: string = "";
	@observable private _errorMessage: string = "";

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	public get errorMessage(): string {
		return this._errorMessage;
	}

	public get terms(): boolean {
		return this._terms;
	}

	public get username(): string {
		return this._username;
	}

	get isSubmitDisabled(): boolean {
		return !this.username.trim();
	}

	dispose(): void {
		return;
	}

	init(param: WithNavigate): void {
		this._navigate = param.navigate;
	}

	onChange(param: WithNavigate): void {
		this._navigate = param.navigate;
	}

	@action
	public onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this._username = event.currentTarget.value;
		this.checkUsername();
	};

	public onSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
		event.preventDefault();

		this._userStore
			.checkUsername(this.username)
			.then(() => {
				this.register();
			})
			.catch((error) => this.onError(error));
	};

	private register() {
		const payload: IRegisterPayload = {
			mmid: this._userStore.mmid!,
			displayName: this.username,
			terms: true,
		};
		Cookies.set("isChangesViewed", "true");
		this._userStore
			.register(payload)
			.then(() => {
				this._navigate?.("/picks");
			})
			.catch((error) => this.onError(error));
	}

	private onError(args: unknown) {
		const error = args as AxiosApiErrorGeneric;
		this._errorMessage = getCheckUsernameErrorObject(error);
	}

	public checkUsername = debounce(() => {
		this._errorMessage = "";

		const nameLength = this.username.length;
		if (nameLength < 6 || nameLength > 20) {
			this._errorMessage = this.i18n.t("username.error.invalid");
			return;
		}

		this._userStore.checkUsername(this.username).catch((error) => this.onError(error));
	}, 2000);
}
